<template>
  <div class="governance erm" id="erm">
    <div class="side-circle top right"></div>
    <div class="ui grid container">
      <div class="intro-content erm">
        <h2>Enterprise Risk Management</h2>
        <div class="paragraph">
          <h4>Risk Policy</h4>
          <p>TayoCash is a company committed to ensuring that all risks are identified and managed at acceptable levels to achieve corporate and business objectives. All necessary legal and regulatory requirements to maintain compliance and manage risks are given the highest importance. A crucial part of its business operations is a well-defined and systematic risk management process, tailored to each business unit’s needs, which ensures that TayoCash shall:
            <ul>
              <li> Identify and measure the risks TayoCash is exposed to and implement appropriate actions to avoid or mitigate these risks;</li>
              <li>Ensure that the risk assessment results serve as a basis of the decision-making process across all levels of the organization; and</li>
              <li>Strive for a robust risk management process through continuous monitoring and improvement.</li>
            </ul>
          </p>
        </div>
        <div class="paragraph">
          <h4>Enterprise Risk Management System</h4>
          <p>In line with this Risk Policy, TayoCash has an Enterprise Risk Management System in place that provides for a complete and careful process containing proactive steps to:
            <span>
              <ol>
                <li>Identify, evaluate, and mitigate prioritized risks; and</li>
                <li>Manage all existing and potential enterprise risks within the company’s risk appetite.</li>
              </ol>
            </span>
          </p>

          <p>Under its Enterprise Risk Management System, TayoCash shall be able to respond appropriately to risks through a standard and informed decision-making structure, which is understood by every TayoCash personnel. This ensures the protection of its customers’ interests, increasing the value brought by TayoCash to them and strengthening TayoCash’s competitive advantage as a company and brand.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'erm'
}
</script>

<style scoped lang='scss'>
  .governance {
    display: flex;
    flex-flow: row;
    align-items: center;
    min-height: 100vh !important;
    background: #FFFFFF;
    overflow: hidden;

    .ui.grid {
      min-height: auto !important;
    }
  }
</style>
